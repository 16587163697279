.action-btn-custom > span {
  width: 100%;
  display: flex;
  align-items: center;
}

.action-btn-custom:hover {
  background-color: white !important;
  color: #d22b50 !important;
  z-index: 1 !important;
}
